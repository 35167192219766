import axios from "axios";
import { config } from "@/lib/config";
async function getDashboardData() {
  const apiKey = "jekdf-gqjowe-fqnhiux-fnqijrwq-fvcnqrjngw-sfgnroq";
  const url = `https://xmaquina-backend-q2lhm.ondigitalocean.app/v1/machines/61/stats`;

  try {
    const response = await axios.get(url, { headers: { "x-api-key": apiKey } });
    return response.data;
  } catch (error) {
    console.log(error);
    return { error: 500 };
  }
}

async function getVehicleData() {
  const apiKey = config.VITE_BACKEND_API_KEY || "";
  const url = `https://lobster-app-5scyq.ondigitalocean.app/v1/token-platform/vehicles`;
  try {
    const response = await axios.get(url, { headers: { "x-api-key": apiKey } });
    return response.data;
  } catch (error) {
    console.log(error);
    return { error: 500 };
  }
}

export { getDashboardData, getVehicleData };
