import { Dialog } from "@headlessui/react";
import { useMemo, useState } from "react";
import close from "@/assets/close.png";
import {
  ADDR as secPoolAddr,
  useBuySecToken,
  useSecPoolData,
} from "@/contracts/eloopNetwork/secPool";
import {
  useApproveMUSDCToken,
  useMUSDCToken,
} from "@/contracts/eloopNetwork/mUSDCToken";
import { waitForTransaction } from "@wagmi/core";
import { peaqAgung } from "@/lib/peaqAgung";
import { toast } from "sonner";
import { useAccount, useQueryClient } from "wagmi";

import { formatErrorMessage } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { formatUnits } from "viem";

const BuySecurityTokenModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [secBuyAmount, setSecBuyAmount] = useState<number>(1);
  const { address } = useAccount();
  const data = useSecPoolData(address ?? "0x0");
  const { balance: mUSDCBalance } = useMUSDCToken();

  const totalPrice = useMemo(() => {
    if (!data.formatted.tokenPrice) return 0;
    return Number(formatUnits(data.formatted.tokenPrice, 18)) * secBuyAmount;
  }, [data.formatted.tokenPrice, secBuyAmount]);

  const { approve: approveMUSDCSecToken } = useApproveMUSDCToken(
    secPoolAddr,
    BigInt(mUSDCBalance.data?.value ?? 0),
    {
      writeConfig: {
        async onSuccess(data) {
          toast("Approving USDM tokens");
          const tx = await waitForTransaction({
            hash: data.hash,
            chainId: peaqAgung.id,
          });
          toast(`Successfully approved USDM (${tx.transactionHash})`);
          wagmiClient.invalidateQueries();
        },
      },
    }
  );
  const wagmiClient = useQueryClient();
  const { buy: buySecToken, prepared: buyPrepared } = useBuySecToken(
    BigInt(`${secBuyAmount}`),
    {
      writeConfig: {
        async onSuccess(data) {
          toast("Buying RD2049");
          const tx = await waitForTransaction({
            hash: data.hash,
            chainId: peaqAgung.id,
          });
          toast(`Successfully bought RD2049 (${tx.transactionHash})`);
          setIsOpen(false);
          wagmiClient.invalidateQueries();
        },
      },
    }
  );
  return (
    <>
      <button
        disabled={
          !(
            data?.formatted?.secTokenBalance !== null &&
            Number(data?.formatted?.secTokenBalance) > 0
          )
        }
        onClick={() => {
          setIsOpen(true);
        }}
        className="text-sm disabled:bg-gray-400 disabled:cursor-default bg-black focus:outline-none z-50 font-light cursor-pointer  text-white px-10  rounded-full py-3 flex justify-center gap-1"
      >
        Buy Tokens
      </button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="h-screen transition-all duration-500 z-50 w-screen fixed top-0 left-0 overflow-hidden bg-opacity-70 bg-gray-900 grid place-content-center">
          <Dialog.Panel className="md:p-10 p-5 mx-5 md:mx-0 md:w-[30rem] bg-white flex flex-col  relative rounded-3xl border shadow gap-2">
            <div
              onClick={() => setIsOpen(false)}
              className="absolute cursor-pointer top-9 right-5"
            >
              <img src={close} />
            </div>
            <div className="flex items-start gap-3 flex-col justify-start">
              <h2 className="text-2xl text-start font-bold">Buy RD2049</h2>
            </div>
            <div className="flex mt-10 flex-col gap-8 items-start">
              <div className="border items-end justify-between flex bg-gray-100 w-full p-4 rounded-lg border-blue-600">
                <div className="flex gap-4 flex-col">
                  <h2 className="text-sm ">Amount</h2>
                  <input
                    className="rounded-md w-2/3 border-0 py-1.5 pl-2 pr-2 text-gray-900  focus:border-none focus:ring-0 focus:outline-none text-2xl  focus:border-transparent  bg-gray-100 border-none placeholder:text-gray-400 "
                    value={secBuyAmount}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setSecBuyAmount(0);
                      } else {
                        setSecBuyAmount(parseInt(e.target.value));
                      }
                    }}
                  />
                </div>
                <p className="border-2  rounded-full text-sm px-4 py-2">
                  RD2049
                </p>
              </div>
              <div className="border items-end justify-between flex bg-gray-100 w-full p-4 rounded-lg border-blue-600">
                <div className="flex gap-4 flex-col">
                  <h2 className="text-sm ">Price</h2>
                  <h2 className="text-gray-400">{totalPrice.toFixed(2)}</h2>
                </div>
                <p className="border-2  rounded-full text-sm px-4 py-2">USDM</p>
              </div>
            </div>
            <div className="flex gap-2 flex-col">
              {buyPrepared.error !== null ? (
                <Button
                  onClick={() => approveMUSDCSecToken.write?.()}
                  loading={approveMUSDCSecToken.isLoading}
                  className="bg-black text-white w-full rounded-full py-3"
                >
                  Approve
                </Button>
              ) : null}
              <Button
                disabled={buyPrepared.error !== null}
                loading={buyPrepared.isLoading || buySecToken.isLoading}
                onClick={() => buySecToken.write?.()}
                className="bg-black text-white w-full  rounded-full py-3"
              >
                Buy
              </Button>
              {buyPrepared.error ? (
                <ErrorMessage message={formatErrorMessage(buyPrepared.error)} />
              ) : null}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

function ErrorMessage({ message }: { message: string }) {
  if (message.includes("Please approve a higher amount"))
    return <p>{message}</p>;

  <p className="text-red-500">Cannot buy RD2049: {message}</p>;
}

export default BuySecurityTokenModal;
