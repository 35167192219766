import { WriteTransactionOptions } from "@/lib/utils";
import {
  UseContractWriteConfig,
  UsePrepareContractWriteConfig,
  erc20ABI,
  useAccount,
  useBalance,
  useContractWrite,
  usePrepareContractWrite,
  useToken,
} from "wagmi";

export const ADDR = "0x4023bE8D45dfC9Fb8FE22E57C58d672BEcb51a56";

export const useMUSDCToken = () => {
  const { address } = useAccount();
  const token = useToken({
    address: ADDR,
  });

  const balance = useBalance({
    token: token.data?.address,
    address: address,
  });

  return { token, balance };
};

export const useApproveMUSDCToken = (
  address: `0x${string}`,
  amount: bigint,
  config?: WriteTransactionOptions<
    UseContractWriteConfig<typeof erc20ABI, "approve", "prepared">,
    UsePrepareContractWriteConfig<typeof erc20ABI, "approve">
  >
) => {
  const { writeConfig, preparedConfig } = config ?? {};
  const prepared = usePrepareContractWrite({
    address: ADDR,
    abi: erc20ABI,
    functionName: "approve",
    args: [address, amount],
    ...preparedConfig,
  });

  const approve = useContractWrite({ ...prepared.config, ...writeConfig });
  return { prepared, approve };
};
