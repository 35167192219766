function Card({
  bg = "bg-[#1e1e1e]",
  title = "Revenue",
  value = "0.00",
  growth = "20",
}) {
  return (
    <div
      className={`h-[120px] md:h-[140px] w-full md:w-1/3  px-10 relative   flex justify-between  items-center  ${bg}   rounded-3xl border shadow-sm`}
    >
      {/* <img src={statDecor} className="absolute top-0 right-0 " /> */}

      <div className="flex  text-white justiify-between items-start  flex-col">
        <h3 className="text-xl font-light">{title}</h3>
        <h3 className=" text-3xl md:text-4xl font-normal">{value} </h3>
      </div>
      <h2 className="px-4 bg-white rounded-full mb-4 invisible  py-1.5">
        {growth}%
      </h2>
    </div>
  );
}

export { Card };
