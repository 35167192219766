import logoMain from "../../../assets/icons/logo-mini.svg";
import icon_wallet from "../../../assets/icons/Wallet_alt_fill.svg";
import icon_profile from "../../../assets/icons/User_box_fill.svg";
// import icon_suitcase from "./assets/icon_suitcase.svg";
import icon_coin from "../../../assets/icons/coin.svg";
import icon_logout from "../../../assets/icons/Sign_out.svg";
import { useNavigate } from "react-router-dom";
import { disconnect } from "@wagmi/core";
import twitter from "./assets/twitter.svg";

export default function Sidebar() {
  const navigator = useNavigate();
  const currentPath = window.location.pathname;
  return (
    <div className="w-20 hidden items-center sm:flex mr-3 bg-white rounded-3xl shadow-md  flex-col justify-between">
      <div className=" w-full h-1/2  justify-center items-center">
        <div className="flex  items-center mt-7  justify-center">
          <img className="items-center w-10" src={logoMain} />
        </div>
        <div className="flex mt-20 gap-4 flex-col items-center  w-full">
          <div
            onClick={() => {
              navigator("/dashboard");
            }}
            className={`h-16 w-16 hover:bg-background rounded-lg  cursor-pointer gap-2 flex items-center   justify-center ${
              (currentPath === "/dashboard" || currentPath === "/") &&
              " font-semibold  bg-slate-200 "
            } `}
          >
            <div className="flex items-center flex-col justify-center gap-2 ">
              <img className="w-5 " src={icon_wallet} />
              <h2 className="text-xs font-light">Wallet</h2>
            </div>
          </div>
          <div
            onClick={() => {
              navigator("/ecosystem");
            }}
            className={`h-16 w-16 hover:bg-background rounded-lg cursor-pointer gap-2 flex items-center  justify-center ${
              currentPath === "/ecosystem" && "  font-semibold  bg-slate-200 "
            } `}
          >
            <div className="flex flex-col items-center justify-center gap-2 ">
              <img className="w-5" src={icon_coin} />
              <h2 className="text-xs font-light">Ecosystem</h2>
            </div>
          </div>
          {/* for prototype we hid */}
          <div
            onClick={() => {
              navigator("/profile");
            }}
            className={`h-16 w-16 hover:bg-background rounded-lg cursor-pointer gap-2 flex items-center  justify-center ${
              currentPath === "/profile" && "  font-semibold  bg-slate-200 "
            } `}
          >
            <div className="flex flex-col items-center justify-center gap-2 ">
              <img className="w-5" src={icon_profile} />
              <h2 className="text-xs font-light">Profile</h2>
            </div>
          </div>

          {/* <div
            onClick={() => {
              navigator("/buy");
            }}
            className={`h-16 w-16 hover:bg-background rounded-lg cursor-pointer gap-2 flex items-center  justify-center ${
              currentPath === "/buy" && "  font-semibold  bg-background"
            } `}
          >
            <div className="flex flex-col items-center justify-center gap-2 ">
              <img className="w-5" src={icon_coin} />
              <h2 className="text-xs font-light">Buy tokens</h2>
            </div>
          </div> */}
        </div>
      </div>
      <div className=" flex flex-col gap-8 cursor-pointer self-center mb-4  items-center text-sm justify-center rounded-lg">
        {/* socials */}
        <div className="gap-5 flex flex-col">
          <img
            onClick={() => {
              window.open("https://twitter.com/Xmaquinanetwork", "_blank");
            }}
            className="w-4 cursor-pointer"
            src={twitter}
          />
        </div>

        <div
          onClick={async () => {
            caches.keys().then((cacheNames) => {
              cacheNames.forEach((cacheName) => {
                caches.delete(cacheName);
              });
            });
            await disconnect();
            localStorage.clear();
            // refresh page
            window.location.reload();
          }}
          className="flex flex-col h-16 w-16 items-center gap-2"
        >
          <img className="w-4" src={icon_logout} />
          <h2 className="text-xs text-gray-600">Log out</h2>
        </div>
      </div>
    </div>
  );
}

export { Sidebar };
