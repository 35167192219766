import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "../../auth/auth";
import bgAsset from "./assets/bg-navbar.svg";
import { isMobile } from "react-device-detect";
import { disconnect } from "@wagmi/core";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from "@/components/ui/menubar";
import { Menu, X } from "lucide-react";
import logoMid from "@/assets/logo-mini.svg";
import eloopLogo from "@/assets/teneo-logo-black.svg";

export default function Navbar() {
  const pathname = useLocation().pathname;
  const navigator = useNavigate();
  if (isMobile) {
    return (
      <div className="w-full relative px-5 flex justify-between  h-[92px] items-center mb-3 md:mb-0  rounded-3xl md:rounded-none md:rounded-t-3xl bg-white z-50">
        <Menubar className="z-50 bg-gray-100 rounded-full h-10 w-10 grid place-content-center ">
          <MenubarMenu>
            <MenubarTrigger>
              <Menu size={10} />
            </MenubarTrigger>
            <MenubarContent>
              <MenubarItem
                onClick={() => {
                  if (pathname !== "/dashboard") navigator("/dashboard");
                }}
              >
                Wallet
              </MenubarItem>
              <MenubarSeparator />
              <MenubarItem
                onClick={() => {
                  if (pathname !== "/ecosystem") navigator("/ecosystem");
                }}
              >
                Ecosystem
              </MenubarItem>
              <MenubarSeparator />
              <MenubarItem
                onClick={() => {
                  if (pathname !== "/profile") navigator("/profile");
                }}
              >
                Profile
              </MenubarItem>
              <MenubarSeparator />
              <MenubarItem
                onClick={async () => {
                  caches.keys().then((cacheNames) => {
                    cacheNames.forEach((cacheName) => {
                      caches.delete(cacheName);
                    });
                  });
                  await disconnect();
                  localStorage.clear();
                  // refresh page
                  window.location.reload();
                }}
              >
                Logout
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>
        </Menubar>
        <div className="flex gap-2 items-center">
          <img src={logoMid} className="h-8" />
          <X className="h-10" />
          <img
            onClick={() => {
              window.open("https://teneo.pro", "_blank");
            }}
            src={eloopLogo}
            className="h-7"
          />
        </div>
        <div className=" w-auto  flex items-center  justify-end">
          <Auth />
        </div>
      </div>
    );
  } else
    return (
      <div className="w-full relative flex justify-between  h-[92px] items-center mb-3 md:mb-0  rounded-3xl md:rounded-none md:rounded-t-3xl bg-white z-50">
        <div className=" absolute w-full  h-full top-0 left-0  rounded-t-3xl ">
          <img
            src={bgAsset}
            className="object-cover w-full h-full rounded-t-3xl"
          />
        </div>
        <h2 className="text-3xl transition-all  text-left h-[20] px-10 flex items-center justify-center font-medium">
          {(pathname === "/" || pathname === "/dashboard") && "Wallet"}
          {/* {pathname === "/profile" && "Profile"} */}
          {/* {pathname === "/buy" && "Buy Tokens"} */}
          {pathname === "/ecosystem" && "Ecosystem"}
        </h2>
        <div className=" w-auto p-5 flex items-center mr-10 justify-center">
          <Auth />
        </div>
      </div>
    );
}

export { Navbar };
