import close from "@/assets/close.png";
import {
  useApproveSecToken,
  useSecToken,
} from "@/contracts/eloopNetwork/secToken";
import {
  ADDR as stakingAddr,
  useDepositStake,
} from "@/contracts/eloopNetwork/stakingContract";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Button } from "@/components/ui/button";
import { waitForTransaction } from "@wagmi/core";
import { peaqAgung } from "@/lib/peaqAgung";
import { toast } from "sonner";
import { formatErrorMessage } from "@/lib/utils";
import { useQueryClient } from "wagmi";

const StakeSecurityTokenModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [stakeAmount, setStakeAmount] = useState<number>(1);
  const wagmiClient = useQueryClient();
  const { balance: secTokenBalance } = useSecToken();
  const { deposit: depositStake, prepared: depositStakePrepared } =
    useDepositStake(BigInt(`${stakeAmount}`), {
      writeConfig: {
        async onSuccess(data) {
          toast("Staking RD2049");
          const tx = await waitForTransaction({
            hash: data.hash,
            chainId: peaqAgung.id,
          });
          toast(`Successfully staked RD2049 (${tx.transactionHash})`);
          setIsOpen(false);
          wagmiClient.invalidateQueries();
        },
      },
    });
  const [isApprovalLoading, setIsApprovalLoading] = useState(false);
  const { approve: approveSecTokenStake, prepared: approvePrepared } =
    useApproveSecToken(stakingAddr, BigInt(`${stakeAmount}`), {
      preparedConfig: {
        enabled: depositStakePrepared.status !== "success",
      },
      writeConfig: {
        async onSuccess(data) {
          toast("Approving RD2049");
          setIsApprovalLoading(true);
          const tx = await waitForTransaction({
            hash: data.hash,
            chainId: peaqAgung.id,
          });
          setIsApprovalLoading(false);
          wagmiClient.invalidateQueries();
          toast(`Successfully approved Security (${tx.transactionHash})`);
        },
      },
    });
  return (
    <>
      <button
        disabled={
          !(
            secTokenBalance.data?.formatted != null &&
            Number(secTokenBalance.data?.formatted) > 0
          )
        }
        onClick={() => {
          setIsOpen(true);
        }}
        className="text-sm  disabled:bg-gray-400 disabled:cursor-default font-light cursor-pointer focus:outline-none  px-10 text-white bg-black  rounded-full py-3 grid place-content-center "
      >
        Enter Reward Pool
      </button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="h-screen transition-all duration-500  z-50 w-screen fixed top-0 left-0 overflow-hidden bg-opacity-70 bg-gray-900 grid place-content-center">
          <Dialog.Panel className="md:p-10 p-5 mx-5 md:mx-0 md:w-[30rem] bg-white flex flex-col  relative rounded-3xl border shadow gap-2">
            <div
              onClick={() => setIsOpen(false)}
              className="absolute cursor-pointer top-5 right-5"
            >
              <img src={close} />
            </div>
            <div className="flex items-start gap-3 flex-col justify-start">
              <h2 className="md:text-2xl text-xl text-start font-bold">
                Stake RD2049
              </h2>
            </div>
            <div className="flex mt-10 flex-col gap-8 items-start">
              <div className="border items-end justify-between flex bg-gray-100 w-full p-4 rounded-lg border-blue-600">
                <div className="flex gap-4 flex-col">
                  <h2 className="text-sm ">You stake</h2>
                  <input
                    className="rounded-md w-2/3 border-0 py-1.5 pl-2 pr-2 text-gray-900  focus:border-none focus:ring-0 focus:outline-none text-2xl  focus:border-transparent  bg-gray-100 border-none placeholder:text-gray-400 "
                    value={stakeAmount}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setStakeAmount(0);
                      } else {
                        setStakeAmount(parseInt(e.target.value));
                      }
                    }}
                  />
                </div>
                <p className="border-2  rounded-full text-sm px-4 py-2">
                  RD2049
                </p>
              </div>
            </div>
            {depositStakePrepared.status !== "success" ? (
              <Button
                onClick={() => approveSecTokenStake.write?.()}
                loading={approveSecTokenStake.isLoading || isApprovalLoading}
                disabled={approvePrepared.status !== "success"}
                className="bg-black text-white  w-full rounded-full py-3"
              >
                Approve
              </Button>
            ) : null}
            {approveSecTokenStake.error ? (
              <p className="text-red-500">
                {approveSecTokenStake.error.message}
              </p>
            ) : null}
            <Button
              loading={depositStakePrepared.isLoading || depositStake.isLoading}
              onClick={() => depositStake.write?.()}
              disabled={depositStakePrepared.status !== "success"}
              className="bg-black mt-5 text-white w-full rounded-full py-3"
            >
              Stake
            </Button>
            {depositStakePrepared.error ? (
              <ErrorMessage
                message={formatErrorMessage(depositStakePrepared.error)}
              />
            ) : null}
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

function ErrorMessage({ message }: { message: string }) {
  if (message.includes("Please approve a higher amount"))
    return <p>{message}</p>;

  <p className="text-red-500">Cannot stake RD2049: {message}</p>;
}

export default StakeSecurityTokenModal;
