// import { MagicOidcConnector } from "@/connector/magicOidcConnector";
import { EIP6963Connector, createWeb3Modal } from "@web3modal/wagmi";
import { FC, PropsWithChildren } from "react";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { peaqAgung } from "@/lib/peaqAgung";
import { InjectedConnector } from "wagmi/connectors/injected";
import { publicProvider } from "wagmi/providers/public";
import { config } from "./config";

import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

const walletConnectProjectId = config.VITE_WALLETCONNECT_PROJECT_ID;
// const magicPk = config.VITE_MAGIC_PUBLISHABLE_KEY;

const chains = [peaqAgung];
const metadata = {
  name: "XMAQUINA Network",
  description: "XMAQUINA Network",
  url: "https://prototype.XMAQUINA.network",
};

const { publicClient } = configureChains(chains, [publicProvider()]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    // new MagicOidcConnector({ options: { apiKey: magicPk }, chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: walletConnectProjectId,
        showQrModal: false,
        metadata,
      },
    }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
  ],
  publicClient,
});

createWeb3Modal({ wagmiConfig, projectId: walletConnectProjectId, chains });

export const WagmiProvider: FC<PropsWithChildren> = ({ children }) => {
  return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
};
