import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "../dashboard/components/navbar";
import Sidebar from "../dashboard/components/sidebar";
// import BuyCoinPage from "../dashboard/buyCoin/buyCoinPage";

import ProfilePage from "../dashboard/profile/profilePage";
import { WalletPage } from "../dashboard/wallet/walletPage";

import { FC, PropsWithChildren, useEffect } from "react";
// import { useBackend } from "@/lib/useBackend";
// import TermsAndConditions from "../termsAndConditions/termsAndConditions";
import { toast } from "sonner";
import { useAccount } from "wagmi";
import { EcosystemPage } from "../dashboard/ecosystem/ecosystemPage";
import NotFound from "./NotFound";

const AuthenticatedRoute: FC<PropsWithChildren> = ({ children }) => {
  // const { accessState } = useBackend();
  const navigate = useNavigate();
  const { address } = useAccount();
  const [search, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (search.get("verified") === "true") {
      console.log("verified");
      toast.success("Your email was verified.", {
        position: "top-center",
        dismissible: true,
        description:
          "You will also receive your some USDM and KREST gas tokens in your wallet shortly. Whitelisting your address can take up to 1 minute.",
      });
    }
    setSearchParams((prev) => {
      prev.delete("verified");
      return prev;
    });
  }, [search]);

  useEffect(() => {
    if (!address) {
      navigate("/login", { replace: true });
    }
  }, [address, navigate]);

  if (!address) {
    console.log("no access token");
    navigate("/login", { replace: true });
    return null;
  }

  return <>{children}</>;
};

export default function DashboardRouter() {
  return (
    <div>
      <div className="h-screen bg-black p-2 flex  w-screen">
        <Sidebar />
        <div className="w-full relative flex-1  flex flex-col ">
          <Navbar />
          <Routes>
            <Route
              path="/dashboard"
              element={
                <AuthenticatedRoute>
                  <WalletPage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/ecosystem"
              element={
                <AuthenticatedRoute>
                  <EcosystemPage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <AuthenticatedRoute>
                  <ProfilePage />
                </AuthenticatedRoute>
              }
            />
            {/* <Route
              path="/buy"
              element={
                <AuthenticatedRoute>
                  <BuyCoinPage />
                </AuthenticatedRoute>
              }
            /> */}
            <Route
              path="/"
              element={
                <AuthenticatedRoute>
                  <WalletPage />
                </AuthenticatedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
