// import { DatePicker } from "@/components/ui/datepicker";
import { Button } from "@/components/ui/button";
import {
  createProfile,
  createProfileBodySchema,
  getSdkToken,
  resendVerificationEmail,
  useAccessToken,
  useBackend,
  useSdkToken,
} from "@/lib/useBackend";
import { zodResolver } from "@hookform/resolvers/zod";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useQueryClient } from "@tanstack/react-query";
import { Fingerprint } from "lucide-react";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { useMutation } from "wagmi";
import { z } from "zod";
import trashSvg from "../assets/trash.svg";

type ProfileFormInputs = z.infer<typeof createProfileBodySchema>;

type NewProfile = {
  ethAddr: string;
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  emailVerified: Date | null;
  createdAt: Date;
  updatedAt: Date;
};

type Profile = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  ethAddr: string;
  emailVerified: string | null;
  kycStatus: string | null;
  createdAt: string;
  updatedAt: string;
};

export default function ProfileWrapper() {
  const { profile, accessState } = useBackend();

  if (profile.isSuccess || profile.isError) {
    return (
      <ProfileSection
        profile={profile.data ?? null}
        accessState={accessState.data ?? null}
      />
    );
  }
  return <p>Loading...</p>;
}

interface ProfileProps {
  profile: Profile | null;
  accessState: string | null;
}

function ProfileSection(props: ProfileProps) {
  const { profile, accessState } = props;
  const [deleteProfile, setDeleteProfile] = useState(false);
  const [showKyc, setShowKyc] = useState(false);

  const [showForm, setShowForm] = useState(profile?.email ? true : false);
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm<ProfileFormInputs>({
    resolver: zodResolver(createProfileBodySchema),
    defaultValues: {
      ...profile,
    },
  });

  const queryClient = useQueryClient();

  const createProfileMutation = useMutation<
    NewProfile,
    Error,
    ProfileFormInputs
  >({
    mutationFn: (data) => {
      if (!accessState) throw new Error("No access token");
      return createProfile(accessState, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["profile"],
      });
      setEditProfile(false);
      toast("Please verify your email address", {
        description: "We have sent you a verification email.",
      });
    },
  });

  const resendVerificationEmailMutation = useMutation({
    mutationFn: () => {
      if (!accessState) throw new Error("No access token");
      return resendVerificationEmail(accessState);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["profile"],
      });
      toast.success("Verification link sent to your email address");
    },
    onError: () => {
      toast.error("Error sending verification link");
    },
  });

  const onSubmit: SubmitHandler<ProfileFormInputs> = async (data) => {
    console.log("onSubmit", data);
    createProfileMutation.mutate(data);
  };
  const [editProfile, setEditProfile] = useState(false);
  return (
    <div className="flex gap-5 w-full">
      {deleteProfile && (
        <DeleteProfilePopup onClose={() => setDeleteProfile(false)} />
      )}
      {showKyc && profile ? (
        <KycPopup onClose={() => setShowKyc(false)} email={profile.email} />
      ) : null}
      <div
        className={`
         'h-auto'
         w-full m-2 items-center bg-white justify-center flex flex-col p-3 pt-4 rounded-3xl gap-4 `}
      >
        <div>
          {!profile?.email && (
            <div className="border mb-6 border-blue-500 rounded-lg py-3 px-3 bg-[#f3f3fb] flex items-center justify-between">
              <h2>To continue work with XMAQUINA please create profile</h2>
              <button
                onClick={() => {
                  setShowForm(true);
                  setEditProfile(true);
                }}
                className="ml-4 bg-primary text-white rounded-full py-2 px-6 focus:outline-none"
              >
                Create Profile
              </button>
            </div>
          )}

          <form
            className={`${
              showForm ? "opacity-100" : "opacity-20 disabled"
            } flex flex-col w-full gap-4 `}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex gap-3 flex-col ">
              <div className="flex w-full gap-4 ">
                <div className="flex w-full gap-3 flex-col">
                  <h2 className="text-sm font-normal">First Name</h2>
                  <input
                    disabled={!editProfile}
                    placeholder="First Name"
                    className="border-2 px-3 rounded-lg py-2 border-gray-200 w-full focus:outline-none"
                    {...register("firstName", { required: true })}
                  />
                </div>
                <div className="flex w-full gap-3 flex-col">
                  <h2 className="text-sm font-normal">Last Name</h2>
                  <input
                    disabled={!editProfile}
                    placeholder="Last Name"
                    className="border-2 px-3 rounded-lg py-2 border-gray-200 w-full focus:outline-none"
                    {...register("lastName", { required: true })}
                  />
                </div>
              </div>
              {errors.firstName && (
                <p className="text-xs italic text-red-500 mt-2">
                  {errors.firstName?.message}
                </p>
              )}
              {errors.lastName && (
                <p className="text-xs italic text-red-500 mt-2">
                  {errors.lastName?.message}
                </p>
              )}
            </div>
            <div className="flex w-full gap-4">
              <div className="flex w-full gap-1 flex-col">
                <h2 className="text-sm font-normal">Email</h2>
                <input
                  disabled={!editProfile}
                  placeholder="hey@XMAQUINA.app"
                  className="border-2 px-3 rounded-lg py-2 border-gray-200 w-full focus:outline-none"
                  {...register("email", { required: true })}
                />
              </div>
            </div>
            {profile?.emailVerified === null && (
              <div className="flex w-full justify-between">
                <div>
                  <h2>Email address is not verified.</h2>
                  <h2 className="text-xs">
                    If you didn't receive the code, click here to send it again.
                  </h2>
                </div>

                <Button
                  className="rounded-full hover:bg-transparent bg-transparent text-black border border-black"
                  onClick={() => resendVerificationEmailMutation.mutate()}
                  loading={resendVerificationEmailMutation.isLoading}
                >
                  Re-send confirmation
                </Button>
              </div>
            )}
            <div className="flex justify-end gap-10">
              {profile?.email && (
                <div
                  onClick={() => setEditProfile(!editProfile)}
                  className={`px-3 cursor-pointer text-center blur-0 z-40 rounded-full py-3  ${
                    editProfile
                      ? "border border-primary text-primary"
                      : "bg-primary text-white"
                  }  w-1/2 mt-2 focus:outline-none"`}
                >
                  {profile?.email && editProfile ? "Cancel" : "Edit Profile"}
                </div>
              )}

              {editProfile && (
                <button
                  type="submit"
                  className="px-3 blur-0 z-40 rounded-full py-3  bg-primary text-white  w-1/2 mt-2 focus:outline-none"
                >
                  {createProfileMutation.isLoading
                    ? "Saving..."
                    : profile
                    ? "Save Changes"
                    : "Create Profile"}
                </button>
              )}
            </div>
          </form>
          {profile && (
            <div className="w-full mt-10 bg-background rounded-3xl p-3 gap-3 mb-10  flex flex-col justify-between">
              <h2 className="text-xl ml-3 font-normal"> Delete profile</h2>
              <div className="bg-white w-full rounded-xl p-4">
                <h2 className="text-gray-400 mb-2 font-light">
                  If you are sure you want to delete your account, click the
                  Delete button.
                </h2>
                <button
                  onClick={() => setDeleteProfile(true)}
                  className="px-3 cursor-pointer blur-0 z-40 rounded-full py-3  border-red-400 border text-red-400  w-1/2 mt-2 focus:outline-none"
                >
                  Delete profile
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// delete profile popup
function DeleteProfilePopup({ onClose }: { onClose?: () => void }) {
  return (
    <div className="grid fade-in-enter place-content-center top-0 left-0 h-screen w-screen bg-gray-900 z-50 bg-opacity-80 fixed ">
      <div className="  mt-10 bg-white items-center rounded-3xl p-8 gap-3 mb-10  flex flex-col justify-center">
        <div className="flex justify-center">
          <img src={trashSvg} className="h-20" />
        </div>
        <h2 className="text-black mb-2 w-72 text-xl  text-center font-light">
          Are you sure you want to delete your profile?
        </h2>
        <h2 className="text-sm text-gray-500 text-center w-72">
          This will delete all of your account data and cannot be recovered
        </h2>
        <div className="flex w-full gap-3 px-2">
          <button
            onClick={() => onClose && onClose()}
            className=" w-44 cursor-pointer blur-0 z-40 rounded-full py-3  bg-primary text-white   mt-2 focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={() => onClose && onClose()}
            className=" w-44 cursor-pointer blur-0 z-40 rounded-full py-3  border-red-400 border text-red-400   mt-2 focus:outline-none"
          >
            Delete profile
          </button>
        </div>
      </div>
    </div>
  );
}

function KycPopup({ onClose, email }: { onClose?: () => void; email: string }) {
  const sdkToken = useSdkToken();
  const accessState = useAccessToken();

  return (
    <div className="grid fade-in-enter place-content-center top-0 left-0 h-screen w-screen bg-gray-900 z-50 bg-opacity-80 fixed ">
      <div className="mt-10 bg-white items-center rounded-3xl p-8 gap-3 mb-10 flex flex-col justify-center">
        <div className="flex justify-center">
          <Fingerprint className="h-10 w-10" />
        </div>
        <h2 className="text-black mb-2 w-72 text-xl  text-center font-light">
          Verification
        </h2>
        {sdkToken.data && (
          <SumsubWebSdk
            accessToken={sdkToken.data}
            testEnv={true}
            expirationHandler={() => {
              if (!accessState.data) throw new Error("No access token");
              return getSdkToken(accessState.data);
            }}
            config={{ email }}
            options={{ debug: true }}
            onMessage={(event) => {
              console.log({ event });
            }}
            onError={(event) => {
              console.log({ event });
            }}
          />
        )}
        <div className="flex w-full gap-3 px-2">
          <button
            onClick={() => onClose && onClose()}
            className=" w-44 cursor-pointer blur-0 z-40 rounded-full py-3  bg-primary text-white   mt-2 focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (sdkToken.data) return;
              sdkToken.mutate();
            }}
            disabled={Boolean(sdkToken.data)}
            className="w-44 cursor-pointer blur-0 z-40 rounded-full py-3 bg-primary text-white mt-2 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Start
          </button>
        </div>
      </div>
    </div>
  );
}
