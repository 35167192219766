import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PageRouter from "./features/routes/main.router";
// import CognitoProvider from "./lib/useCognito";
import { WagmiProvider } from "./lib/WagmiProvider";
import { Toaster } from "sonner";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
// import logo from "./assets/logo.svg";

const queryClient = new QueryClient();

function App() {
  const [haveMetamask, setHaveMetamask] = useState(false);
  useEffect(() => {
    if (isMobile) {
      if (window.ethereum) {
        setHaveMetamask(true);
      }
    }
  }, []);

  if (isMobile && !haveMetamask) {
    return (
      <div className="flex overflow-hidden justify-center items-center h-screen">
        <h1 className="text-sm px-10 text-center">
          <div className="flex flex-col gap-4">
            <h1>
              Use metamask app on your mobile device or use a desktop browser to
              access the prototype.
            </h1>
            <a
              href="https://metamask.app.link/dapp/token2049.xmaquina.io"
              target="_blank"
              className="bg-blue-500 text-white p-2 rounded-lg"
            >
              Open in Metamask
            </a>
          </div>
        </h1>
      </div>
    );
  } else
    return (
      <QueryClientProvider client={queryClient}>
        <Toaster richColors closeButton />
        {/* <CognitoProvider> */}
        <WagmiProvider>
          <PageRouter />
        </WagmiProvider>
        {/* </CognitoProvider> */}
      </QueryClientProvider>
    );
}

export default App;
