import { z } from "zod";

const envSchema = z.object({
  // VITE_MAGIC_PUBLISHABLE_KEY: z.string(),
  // VITE_MAGIC_PROVIDER_ID: z.string(),
  // VITE_COGNITO_REGION: z.string(),
  // VITE_COGNITO_USER_POOL_ID: z.string(),
  // VITE_COGNITO_USER_POOL_APP_CLIENT_ID: z.string(),
  VITE_WALLETCONNECT_PROJECT_ID: z.string(),
  VITE_BACKEND_ADDR: z.string(),
  VITE_BACKEND_API_KEY: z.string(),
  VITE_DOOR_CODE: z.string(),
});

export type Env = z.infer<typeof envSchema>;

const config = envSchema.parse(import.meta.env);

export { envSchema, config };
