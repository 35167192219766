//  @ts-nocheck
// import googleLogo from "@/assets/google.svg";

import { config } from "@/lib/config";
const PASSWORD = config.VITE_DOOR_CODE;
import loginLeft from "@/assets/login-left.png";
import loginRight from "@/assets/login-right.png";
import logo from "@/assets/logo.svg";
// import { useCognito } from "@/lib/useCognito";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { useEffect, useState } from "react";
// import { SubmitHandler, useForm } from "react-hook-form";
// import { useAccount, useConnect } from "wagmi";
// import script from "./w3Button.js";
// import { z } from "zod";
import LoadingOverlay from "../dashboard/wallet/components/loadingOverlay";
import { useConnect } from "wagmi";
import { useState } from "react";

// const LoginFormSchema = z.object({
//   email: z.string().email(),
//   password: z.string().min(8),
// });

// type LoginFormInputs = z.infer<typeof LoginFormSchema>;

const LoginPage = () => {
  // const { connector: activeConnector, isConnected } = useAccount();
  // const { connect, connectors, error, pendingConnector } = useConnect();

  const [isAllowed, setIsAllowed] = useState(false);
  const [password, setPassword] = useState("");
  const [wrongPassword, setWrongPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // const { signInWithEmail, accessToken } = useCognito();
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm<LoginFormInputs>({ resolver: zodResolver(LoginFormSchema) });

  // const [isSigningIn, setIsSigningIn] = useState<boolean>(false);

  // const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
  //   setIsSigningIn(true);
  //   await signInWithEmail(data.email, data.password);
  //   setIsSigningIn(false);
  // };

  // useEffect(() => {
  //   // if user logged in with email, use magic connector to setup wallet
  //   if (accessToken && accessToken.length > 0 && !isConnected) {
  //     const connector = connectors.find((connector) =>
  //       connector.name.toLowerCase().includes("magic")
  //     );

  //     if (connector) {
  //       connect({ connector });
  //     }
  //   }
  // }, [accessToken, connect, connectors, isConnected]);

  // loading screen until createWeb3Modal is called
  const { connectors, error } = useConnect();
  if (!connectors) {
    return <LoadingOverlay />;
  }

  return (
    <div className="flex-1 grid overflow-hidden bg-black  mb-10 h-screen  w-screen  place-content-center  ">
      <div className="absolute right-0 overflow-hidden invisible md:visible top-0  h-72 object-fit  z-0 select-none ">
        <img src={loginLeft} className="translate-x-56 object-fit h-full" />
      </div>
      <div className="absolute overflow-hidden invisible md:visible h-72 left-0  rotate-180  bottom-0   z-0  select-none ">
        <img src={loginRight} className="object-fit translate-x-56  h-full" />
      </div>
      <nav className="w-screen flex  items-center absolute h-24">
        <div className="w-80 justify-center flex items-center h-full">
          <img src={logo} className="w-14" alt="Logo" />
        </div>

        <div className="w-80" />
      </nav>
      <div className="flex w-[26rem] items-center flex-col gap-5 z-10">
        <h2 className="text-3xl text-white font-semibold mb-5">
          Don't Fear The Robots
        </h2>
        {/* {isConnected && <div>Connected to {activeConnector?.name}</div>} */}
        {/* //@ts-ignore */}

        <div>
          <div className="p-2 bg-gray-700 rounded-full text-white">
            <w3m-button label="Connect your Wallet" />
          </div>
          {error && <div>{error.message}</div>}
        </div>

        {/* <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-full gap-5 flex-col"
        >
          <div className="flex w-full flex-col items-start gap-1">
            <label className="text-xs text-gray-500">
              Email / Phone Number
            </label>
            <input
              className="border-md bg-white text-sm w-full px-4 py-2.5 rounded-lg border"
              placeholder="hello@yourcompany.com"
              type="email"
              id="email"
              {...register("email")}
            />
            {errors.email && (
              <p className="text-xs italic text-red-500 mt-2">
                {errors.email?.message}
              </p>
            )}
          </div>
          <div className="flex w-full flex-col items-start gap-1">
            <label className="text-xs text-gray-500">Password</label>
            <input
              className="border-md bg-white text-sm w-full px-4 py-2.5 rounded-lg border"
              placeholder="Password"
              type="password"
              id="password"
              {...register("password")}
            />
            {errors.password && (
              <p className="text-xs italic text-red-500 mt-2">
                {errors.password?.message}
              </p>
            )}
          </div>
          <button
            id="accept"
            name="submit"
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-full"
            disabled={isSigningIn || Boolean(pendingConnector)}
          >
            {isSigningIn || Boolean(pendingConnector)
              ? "Signing in..."
              : "Sign in"}
          </button>
        </form> */}
        {/* <div className="flex w-full gap-5 items-center justify-between">
          <div className="h-[1px] bg-blue-400 w-full" />

          <h2>or</h2>
          <div className="h-[1px] bg-blue-400 w-full" />
        </div> */}
        {/* <button className="w-full text-xs gap-1.5 bg-blue-100 border border-blue-500 flex justify-center items-center py-2.5 rounded-full">
          <img src={googleLogo} className="w-3 mb-0.5 " />
          Continue with Google
        </button>
        <div className="h-[1px]  bg-blue-400 w-full" />
        <a className="flex font-light text-sm gap-1" href="forgot-password">
          <h1>Forgot password?</h1>
          <span className="text-blue-700">Reset</span>
        </a> */}
      </div>
    </div>
  );
};

export default LoginPage;
