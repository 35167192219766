// import { useBackend } from "@/lib/useBackend";
import bellIcon from "../dashboard/components/assets/bell.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState } from "react";
import { Copy, CopyCheck } from "lucide-react";
import { useAccount } from "wagmi";
function Auth() {
  const [showNotif, setShowNotif] = useState(false);
  // const { profile } = useBackend();
  const { address } = useAccount();
  const [copied, setCopied] = useState(false);

  return (
    <div className="flex gap-4  md:items-center">
      {showNotif && <Notification />}

      <div
        className="cursor-pointer z-50 bg-gray-100 grid place-content-center rounded-full h-10 w-10"
        onClick={() => setShowNotif(!showNotif)}
      >
        <img src={bellIcon} className="h-5 " />
      </div>

      <div className=" hidden md:flex cursor-pointer z-50 flex-row w-4/5 justify-between rounded-full bg-gray-50  p-1  items-center">
        <div className="flex cursor-pointer pr-6  flex-row justify-between w-full items-center gap-2">
          <CopyToClipboard
            text={address}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 1000);
            }}
          >
            <div className="bg-gray-100   grid place-content-center rounded-full w-12 h-12">
              {copied ? (
                <CopyCheck
                  className="text-green-400 cursor-pointer"
                  size={24}
                />
              ) : (
                <Copy className="text-gray-600 cursor-pointer" size={24} />
              )}
            </div>
          </CopyToClipboard>

          <h2 className="font-normal px-2  truncate w-40">{address}</h2>
        </div>
      </div>
    </div>
  );
}

function Notification() {
  return (
    <div className="absolute fade-in-enter flex gap-4 flex-col top-24 md:right-16 right-0 w-80 p-4 bg-white shadow-lg rounded-3xl">
      <h2 className="font-semibold">Notification</h2>
      <div className="flex gap-4 p-9 ">
        <h2 className="text-xl text-gray-500 text-center">
          There are no new notifications
        </h2>
      </div>
    </div>
  );
}

export { Auth };
