import { useAccount } from "wagmi";

export default function WalletSection() {
  const { address } = useAccount();

  return (
    <div className="w-1/2  flex flex-col pt-4 p-2 rounded-3xl gap-4">
      <h2 className=" text-xl">Wallet address</h2>
      <input
        disabled
        value={address}
        className="bg-[#f8f9ff] px-3 rounded-lg py-3 mb-2  w-full focus:outline-none"
      />
    </div>
  );
}
