import { BrowserRouter } from "react-router-dom";
import LoginPage from "../auth/login-page";

// import { useBackend } from "@/lib/useBackend";

import { useEffect } from "react";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { peaqAgung } from "@/lib/peaqAgung";
import DashboardRouter from "./dashboard.router";

function PageRouter() {
  // const { accessState } = useBackend();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { address } = useAccount();

  // const accessToken = accessState.data;

  useEffect(() => {
    if (chain && chain.id !== peaqAgung.id && switchNetwork) {
      switchNetwork(peaqAgung.id);
    }
  }, [chain, switchNetwork]);

  return (
    <div className="h-screen bg-black flex  w-screen">
      <BrowserRouter>
        {address ? <DashboardRouter /> : <LoginPage />}
      </BrowserRouter>
    </div>
  );
}

export default PageRouter;
