import discordLogo from "../assets/discord-logo.png";
function DiscordPopup({ closePopup }: { closePopup: () => void }) {
  // popup to join discord channel.
  return (
    <div className="w-full">
      <h2 className="text-2xl font-semibold">Get USDM</h2>
      <h2 className="text-sm my-4 text-gray-400">
        Follow the link to purchase USDM
      </h2>

      <div className=" h-40 py-4 px-5 flex flex-col items-center justify-around bg-gray-100 rounded-md">
        <div className="flex items-center gap-4">
          <img src={discordLogo} />
        </div>

        <div className="flex items-start flex-col w-full gap-3">
          <h2 className="text-sm text-gray-900">Follow the link</h2>
          <h2
            onClick={() =>
              window.open("https://discord.com/invite/AKkGA4ENqc", "_blank")
            }
            className="text-sm cursor-pointer bg-white w-full rounded-lg py-2 px-4 text-gray-900"
          >
            https://discord.com/invite/AKkGA4ENqc
          </h2>
        </div>
      </div>

      {/* 3 step instructions */}
      <div className="flex flex-col gap-2 mt-5 w-full">
        <div className="flex items-start gap-4">
          <h2 className="h-8 w-8 rounded-full bg-gray-200 grid place-content-center">
            1
          </h2>
          <h2 className="text-sm w-11/12  text-gray-400 font-normal">
            Join the Discord Channel
          </h2>
        </div>
        <div className="flex items-start gap-4">
          <h2 className="h-8 w-8 rounded-full bg-gray-200 grid place-content-center">
            2
          </h2>
          <h2 className="text-sm w-11/12  text-gray-400 font-normal">
            go to the #test-faucet-whitelisting channel
          </h2>
        </div>
        <div className="flex items-start gap-4">
          <h2 className="h-8 w-8 rounded-full bg-gray-200 grid place-content-center">
            3
          </h2>
          <h2 className="text-sm w-11/12 align-middle  text-gray-400 font-normal">
            type: &nbsp;
            <code className="bg-gray-600 text-white">
              !whitelist {"<your wallet address>"}{" "}
            </code>
            <br />
            to get whitelisted
          </h2>
        </div>
        <div className="flex items-start gap-4">
          <h2 className="h-8 w-8 rounded-full bg-gray-200 grid place-content-center">
            3
          </h2>
          <h2 className="text-sm w-11/12 align-middle  text-gray-400 font-normal">
            type: &nbsp;
            <code className="bg-gray-600 text-white">
              !send {"<your wallet address>"}{" "}
            </code>
            <br />
            to get USDM
          </h2>
        </div>
      </div>

      <div className="flex gap-2 mt-5">
        <button
          onClick={() => closePopup()}
          className="bg-[#f4f5fb] text-gray-500 w-48 py-2 rounded-3xl"
        >
          Cancel
        </button>
        <button
          onClick={() =>
            window.open("https://discord.com/invite/AKkGA4ENqc", "_blank")
          }
          className="bg-[#5263ff] text-white w-48 py-2 rounded-3xl"
        >
          Get USDM
        </button>
      </div>
    </div>
  );
}

export default DiscordPopup;
