import statDecor from "../assets/statDecor.png";
interface CardProps {
  title?: string;
  value?: string;
  border?: string;
  className?: string;
  actions?: React.ReactNode[];
}
function Card({
  title = "Revenue",
  value = "0.00",
  border = "border-transparent",
  className = "",
  actions,
}: CardProps) {
  return (
    <div
      className={` w-full py-4   px-10 relative  ${
        border && "border " + border
      } ${className}  flex justify-between  items-center  bg-white   rounded-3xl  `}
    >
      <img src={statDecor} className="absolute top-0 right-0 " />

      <div className="flex  gap-2 text-gray-700 justiify-between items-start  flex-col">
        <h3 className="text-xl font-light">{title}</h3>
        <h3 className="text-2xl font-normal">{value} </h3>
        <div className="flex flex-col items-center justify-center md:flex-row gap-5">
          {actions}
        </div>
      </div>
    </div>
  );
}

export { Card };
