import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { ContractFunctionExecutionError } from "viem";
import { z } from "zod";
import { formatUnits } from "viem";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function maxBuyCalculator({ balance, tokenPrice, feePercentage }) {
  // this function calcs the max token a user can buy with his current balance
  try {
    let maxToken: number = 0;
    const minLeftover: number = 0.00001;
    maxToken = balance / tokenPrice / (1 + feePercentage);
    const fee: number = maxToken * tokenPrice * feePercentage;
    // we take away minLeftover from the maxToken to not run into issues with rounding errors
    maxToken -= minLeftover;
    return {
      balance,
      tokenPrice,
      feePercentage,
      maxToken,
      fee,
    };
  } catch (e) {
    console.log(e);
    return {
      balance,
      tokenPrice,
      feePercentage,
      maxToken: 0,
      fee: 0,
    };
  }
}

export type WriteTransactionOptions<
  WriteConfig = undefined,
  PreparedConfig = undefined
> = {
  writeConfig?: WriteConfig;
  preparedConfig?: PreparedConfig;
};

export const formatErrorMessage = (error: unknown) => {
  // if error is undefined or null, return ''
  if (error === undefined || error === null) return "";
  try {
    if (error instanceof ContractFunctionExecutionError) {
      const parts = error.cause.message.split("\n").filter((x) => x !== "");
      console.log(parts[1]);
      if (parts[1].includes("ERC20InsufficientAllowance")) {
        return "Insufficient allowance. Please approve a higher amount.";
      }

      if (parts[1].includes("ERC20InsufficientBalance")) {
        return "Insufficient balance.";
      }

      if (parts[1].includes("AmountZero()")) {
        return "Amount must be greater than zero.";
      }

      if (parts[1].includes("InsufficientSecurityTokens()")) {
        return "Insufficient RD2049.";
      }

      if (parts[1].includes("0x8067a9e2")) {
        return "Insufficient permissions.";
      }
      if (parts[1].includes("InvalidAmountOrPrice()")) {
        return "Amount must be greater than zero.";
      }

      return parts[1];
    }

    if (error instanceof Error) {
      if (error.message.includes("User rejected the request.")) {
        return "User rejected the request.";
      }
    }

    return "Unknown error";
  } catch (error) {
    return "Unknown error";
  }
};

export const erc20Filler = [
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "string",
        name: "symbol",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "initialSupply",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "allowance",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "needed",
        type: "uint256",
      },
    ],
    name: "ERC20InsufficientAllowance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "balance",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "needed",
        type: "uint256",
      },
    ],
    name: "ERC20InsufficientBalance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "approver",
        type: "address",
      },
    ],
    name: "ERC20InvalidApprover",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address",
      },
    ],
    name: "ERC20InvalidReceiver",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ERC20InvalidSender",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "ERC20InvalidSpender",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "mint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export const ProfileSchema = z.object({
  ethAddr: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  id: z.string(),
  emailVerified: z.string().nullable(),
  kycStatus: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export function roundNumber(number: bigint, decimalPlaces: number) {
  const formatted = formatUnits(number, 18);
  const numbered = Number(formatted);
  const rounded =
    Math.round(numbered * 10 ** decimalPlaces) / 10 ** decimalPlaces;
  return rounded;
}
