import { Fragment, useEffect, useMemo, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import iconConfirmed from "../assets/icon_security.svg";
import { peaqAgung } from "@/lib/peaqAgung";
import {
  ADDR as stakingAddr,
  useDepositStake,
} from "@/contracts/eloopNetwork/stakingContract";
import { useAccount, useQueryClient } from "wagmi";
import { toast } from "sonner";
import {
  ADDR as secPoolAddr,
  useBuySecToken,
  useSecPoolData,
} from "@/contracts/eloopNetwork/secPool";
import { useApproveMUSDCToken } from "@/contracts/eloopNetwork/mUSDCToken";
import { waitForTransaction } from "@wagmi/core";
import { useApproveSecToken } from "@/contracts/eloopNetwork/secToken";
import { Label } from "@/components/ui/label";
import { formatUnits, parseUnits } from "viem";
import { formatErrorMessage } from "@/lib/utils";
import HashLoader from "react-spinners/HashLoader";

export default function BuyAndStake({ isBuyPopupOpen, setIsBuyPopupOpen }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [amount, setAmount] = useState(1);

  const reset = () => {
    setCurrentPage(1);
    setAmount(0);
    setIsBuyPopupOpen(false);
  };

  return (
    <Transition appear show={isBuyPopupOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full  max-w-md transform overflow-hidden rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all">
                {currentPage === 1 && (
                  <SelectAmount
                    setCurrentPage={setCurrentPage}
                    amount={amount}
                    setAmount={setAmount}
                    reset={reset}
                  />
                )}
                {currentPage === 2 && (
                  <WaitingForTransactions
                    setCurrentPage={setCurrentPage}
                    amount={amount}
                    reset={reset}
                  />
                )}
                {currentPage === 3 && (
                  <Confirmed amount={amount} reset={reset} />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function SelectAmount({ setCurrentPage, amount, setAmount, reset }) {
  const { address } = useAccount();
  const data = useSecPoolData(address ?? "0x0");

  const totalPrice = useMemo(() => {
    if (!data.formatted.tokenPrice) return 0;
    return Number(formatUnits(data.formatted.tokenPrice, 18)) * amount;
  }, [data.formatted.tokenPrice, amount]);

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl"> How many RD2049 would you want to stake ?</h2>

      <div className=" flex flex-col gap-5">
        <p className="text-sm text-gray-500">
          This action will first exchange your mUSDC to RD2049 and then stake
          the RD2049 tokens. Every transaction needs to be approved by your
          wallet.
        </p>

        <div className="grid w-full max-w-sm items-center gap-1.5">
          <div className="flex gap-2 items-center">
            <input
              id="amount"
              value={amount}
              onChange={(e) => {
                if (e.target.value === "") {
                  setAmount(0);
                } else {
                  setAmount(parseInt(e.target.value));
                }
              }}
              className="w-full  p-2 focus:outline-none rounded-lg border border-gray-300"
              placeholder="Amount of RD2049"
            />
            <Label htmlFor="amount">RD2049</Label>
          </div>
          <p>Total cost: {totalPrice.toFixed(2)} mUSDC</p>
        </div>
      </div>

      <div className=" flex mt-2 justify-between gap-5">
        <button
          type="button"
          className="inline-flex w-1/2 justify-center rounded-full border border-[#5263ff]  px-4 py-2 text-sm font-medium text-[#5263ff]  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          onClick={reset}
        >
          Cancel
        </button>
        <button
          disabled={amount === 0}
          type="button"
          className="inline-flex w-1/2 disabled:bg-gray-300 justify-center rounded-full border border-transparent bg-[#5263ff] px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          onClick={() => setCurrentPage(2)}
        >
          Buy and Stake token
        </button>
      </div>
    </div>
  );
}

enum Steps {
  ApproveMUSDC = 1,
  BuySecToken = 2,
  ApproveSecToken = 3,
  DepositStake = 4,
}

function WaitingForTransactions({ setCurrentPage, amount, reset }) {
  const { address } = useAccount();
  const data = useSecPoolData(address ?? "0x0");

  const [removePage1Errors, setRemovePage1Errors] = useState<boolean>(false);
  const [removePage2Errors, setRemovePage2Errors] = useState<boolean>(false);
  const [removePage3Errors, setRemovePage3Errors] = useState<boolean>(false);
  const [removePage4Errors, setRemovePage4Errors] = useState<boolean>(false);

  const [_, setHasApprovedMUSDC] = useState<boolean>(false);
  const [__, setHasApprovedSecToken] = useState<boolean>(false);
  const [___, setHasBoughtSecToken] = useState<boolean>(false);

  const totalPrice = useMemo(() => {
    if (!data.formatted.tokenPrice) return 0;
    return Number(formatUnits(data.formatted.tokenPrice, 18)) * amount;
  }, [data.formatted.tokenPrice, amount]);

  const { approve: approveMUSDCSecToken, prepared: approveMUSDCprepared } =
    useApproveMUSDCToken(
      secPoolAddr,
      BigInt(parseUnits(totalPrice.toString(), 18).toString()),
      {
        writeConfig: {
          async onSuccess(data) {
            toast("Approving mUSDC tokens");
            setRemovePage1Errors(true);
            const tx = await waitForTransaction({
              hash: data.hash,
              chainId: peaqAgung.id,
            });
            setHasApprovedMUSDC(true);
            toast(`Successfully approved mUSDC (${tx.transactionHash})`);
            setCurrentStep(2);
            wagmiClient.invalidateQueries();
          },
        },
      }
    );
  const wagmiClient = useQueryClient();
  const { buy: buySecToken, prepared: preparedBuy } = useBuySecToken(
    BigInt(`${amount}`),
    {
      writeConfig: {
        async onSuccess(data) {
          toast("Buying RD2049");
          setRemovePage2Errors(true);
          const tx = await waitForTransaction({
            hash: data.hash,
            chainId: peaqAgung.id,
          });
          setHasBoughtSecToken(true);
          toast(`Successfully bought RD2049 (${tx.transactionHash})`);
          setCurrentStep(3);

          wagmiClient.invalidateQueries();
        },
      },
    }
  );

  const { deposit: depositStake, prepared: depositStakePrepared } =
    useDepositStake(BigInt(`${amount}`), {
      writeConfig: {
        async onSuccess(data) {
          setRemovePage4Errors(true);
          toast("Staking RD2049");
          const tx = await waitForTransaction({
            hash: data.hash,
            chainId: peaqAgung.id,
          });
          toast(`Successfully staked RD2049 (${tx.transactionHash})`);
          setCurrentPage(3);
          wagmiClient.invalidateQueries();
        },
      },
    });

  const { approve: approveSecTokenStake, prepared: approveSecTokenPrepared } =
    useApproveSecToken(stakingAddr, BigInt(`${amount}`), {
      preparedConfig: {
        enabled: depositStakePrepared.status !== "success",
      },
      writeConfig: {
        async onSuccess(data) {
          toast("Approving RD2049");
          setRemovePage3Errors(true);
          const tx = await waitForTransaction({
            hash: data.hash,
            chainId: peaqAgung.id,
          });
          setHasApprovedSecToken(true);
          toast(`Successfully approved RD2049 (${tx.transactionHash})`);
          setCurrentStep(4);
          wagmiClient.invalidateQueries();
        },
      },
    });

  // const shouldApproveMUSDC =
  //   preparedBuy.isError &&
  //   preparedBuy.error?.message.toLowerCase().includes("insufficientallowance");

  // const shouldApproveSecToken =
  //   depositStakePrepared.isError &&
  //   depositStakePrepared.error?.message
  //     .toLowerCase()
  //     .includes("insufficientallowance");

  const [currentStep, setCurrentStep] = useState<Steps>(Steps.ApproveMUSDC);

  useEffect(() => {
    if (currentStep === 1 && approveMUSDCSecToken.isIdle)
      approveMUSDCSecToken.write?.();
    if (currentStep === 2 && buySecToken.isIdle) buySecToken.write?.();
    if (currentStep === 3 && approveSecTokenStake.isIdle)
      approveSecTokenStake.write?.();
    if (currentStep === 4 && depositStake.isIdle) depositStake.write?.();
  }, [
    approveMUSDCSecToken,
    approveSecTokenStake,
    buySecToken,
    currentStep,
    depositStake,
  ]);

  return (
    <div className="flex flex-col items-center">
      <HashLoader color="#5263ff" />

      <h2 className="text-2xl mt-5">Waiting for confirmation...</h2>

      <div className="mt-2 flex gap-1 flex-col items-center">
        <p className="text-lg text-gray-700">
          {currentStep} out of 4 transactions <span className="">complete</span>
        </p>

        <h2 className="text-sm text-gray-400">
          {currentStep === 1 && "Approving Asset Token amount, please wait..."}
          {currentStep === 2 && "Buying Asset Token, please wait..."}
          {currentStep === 3 && "Approving staking amount, please wait..."}
          {currentStep === 4 && "Staking, please wait..."}
        </h2>
        <h2 className="text-sm text-red-400 text-center p-1">
          {currentStep === 1 && !removePage1Errors && (
            <>
              <p>{formatErrorMessage(approveMUSDCprepared.error!)}</p>
              <p>{formatErrorMessage(approveMUSDCSecToken.error!)}</p>
            </>
          )}
          {currentStep === 2 && !removePage2Errors && (
            <>
              <p>{formatErrorMessage(preparedBuy.error!)}</p>
              <p>{formatErrorMessage(buySecToken.error!)}</p>
            </>
          )}
          {currentStep === 3 && !removePage3Errors && (
            <>
              <p>{formatErrorMessage(approveSecTokenPrepared.error!)}</p>
              <p>{formatErrorMessage(approveSecTokenStake.error!)}</p>
            </>
          )}
          {currentStep === 4 && !removePage4Errors && (
            <>
              <p>{formatErrorMessage(depositStakePrepared.error!)}</p>
              <p>{formatErrorMessage(depositStake.error!)}</p>
            </>
          )}
        </h2>

        <button
          type="button"
          className="inline-flex mt-3 w-1/2 justify-center rounded-full border border-[#5263ff]  px-4 py-2 text-sm font-medium text-[#5263ff]  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          onClick={reset}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

function Confirmed({ amount, reset }) {
  return (
    <div className="flex flex-col gap-4 items-center">
      <img className="w-28" src={iconConfirmed} alt="icon confirmed" />
      <h2 className="text-3xl mt-5">Confirmed</h2>
      <h2 className="text-sm text-gray-500">
        {" "}
        You staked {amount} RD2049 to XMAQUINA pool
      </h2>

      <button
        type="button"
        className="mt-4  inline-flex justify-center rounded-full border border-transparent bg-[#5263ff] px-20 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        onClick={reset}
      >
        Done
      </button>
    </div>
  );
}
