import { useEffect, useState } from "react";
import LoadingOverlay from "../wallet/components/loadingOverlay";
import ProfileSection from "./sections/profile";
import WalletSection from "./sections/wallet";
// import { useBackend } from "@/lib/useBackend";
import { User2 } from "lucide-react";

export default function ProfilePage() {
  const [loading, setLoading] = useState(true);
  const [selectedSection, setSelectedSection] = useState("profile");
  // const { profile } = useBackend();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="flex-1 bg-white  gap-6 p-4 pt-2 pb-0 rounded-b-3xl no-scrollbar  flex flex-col overflow-scroll">
      {loading ? (
        <div className="grid h-full place-content-center ">
          <LoadingOverlay />
        </div>
      ) : (
        <div className="no-scrollbar">
          <div
            className={`w-full  bg-background flex flex-col p-2 pt-4 rounded-3xl gap-4`}
          >
            <div className="flex pl-5 items-center justify-start">
              <div className="h-24 w-24 cursor-pointer border-4 mr-5 border-white  text-white font-semibold rounded-full flex flex-col items-center justify-center text-xs text-center">
                <User2 color="#484d73" />
              </div>
              {/* <div className="flex flex-col items-start justify-start">
                <h2 className=" text-2xl flex items-center">
                  {profile.data?.firstName || "EOT"}{" "}
                  {profile.data?.lastName || "USER"}
                </h2>
                <h2>{profile.data?.email || ""} </h2>
              </div> */}
            </div>
            <div className="w-full flex flex-col items-center bg-white rounded-3xl  h-full ">
              <div className="flex  pl-5 pt-5 gap-6 items-center justify-start w-full">
                <SectionTitle
                  title="My Profile"
                  isActive={selectedSection === "profile"}
                  onClick={() => setSelectedSection("profile")}
                />
                {/* {profile.data && ( */}
                <SectionTitle
                  title="Wallet"
                  isActive={selectedSection === "wallet"}
                  onClick={() => setSelectedSection("wallet")}
                />
                {/* )} */}
              </div>

              {selectedSection === "profile" && <ProfileSection />}
              {selectedSection === "wallet" && <WalletSection />}
            </div>
          </div>
          <h2 className="text-2xl font-light my-4 ml-5 text-gray-800">
            Additional information
          </h2>
          <div className="w-full border-8 border-background rounded-3xl p-10 gap-5 mb-10    bg-white  flex justify-between">
            <div className="flex flex-col items-start ">
              <h2 className="text-lg font-semibold mb-4">Links</h2>
              <ul className="dashed list-inside gap-5 ">
                <li className="text-gray-500 my-2 cursor-pointer">
                  <a href="https://www.xmaquina.io/" target="_blank">
                    {" "}
                    XMAQUINA
                  </a>
                </li>
                <li className="text-gray-500 my-2 cursor-pointer">
                  {" "}
                  <a href="https://teneo.pro" target="_blank">
                    Teneo Protocol
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <h2 className=" text-sm -mt-5 mb-5 self-center w-full text-center  text-gray-500">
            Powered by{" "}
            <span
              onClick={() => window.open("https://teneo.pro", "_blank")}
              className="text-blue-500 cursor-pointer"
            >
              Teneo Protocol
            </span>
          </h2>
        </div>
      )}
    </div>
  );
}

function SectionTitle({
  title,
  isActive,
  onClick,
}: {
  title: string;
  isActive: boolean;
  onClick?: () => void;
}) {
  return (
    <div onClick={onClick} className="flex flex-col cursor-pointer">
      <h2
        className={`px-3 mb-2 ${isActive ? "text-[#5263ff]" : "text-gray-500"}`}
      >
        {title}
      </h2>
      {isActive && <div className={`bg-[#5263ff] h-0.5 w-full`} />}
    </div>
  );
}
