import robotImage from "../assets/robot.png";

export default function CarsTable({ machineData }: { machineData: any }) {
  return (
    <>
      <div>
        <div className="bg-[#f4f5fb] border-8 h-auto  rounded-3xl border-[#f4f5fb] mt-5">
          <div className="flex md:flex-row flex-col justify-center items-center gap-2 bg-white w-full borde-b md:p-5 p-2">
            <div className="md:w-1/3 w-full h-auto justify-center gap-5 items-center flex flex-col  rounded-3xl p-2">
              <img src={robotImage} className="object-contain w-72 -mb-12" />
              <h2 className="text-lg font-bold">{machineData?.machine_name}</h2>

              <div className="bg-[#08c4a5] px-5  py-1 rounded-3xl flex items-center justify-center">
                <h2 className="text-white text-sm text-center">Available</h2>
              </div>
            </div>
            <div className="grid h-auto w-full  grid-cols-2 gap-3">
              <TokenStats title="Value" value={`100000 RD2049`} />

              <TokenStats
                title="Average items per order"
                value={`${machineData?.average_items_per_order.toFixed(2)}`}
              />
              <TokenStats
                title="Average revenue per order"
                value={`${machineData?.average_revenue_per_order?.toFixed(
                  2
                )}  AED`}
              />
              <TokenStats
                title="Average toppings per order"
                value={`${machineData.average_toppings_per_order.toFixed(2)} `}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function TokenStats({
  active = false,
  title = "Cashout balance",
  value = "0.00 AED",
}) {
  return (
    <div
      className={`col-span-1 ${
        active ? "bg-white text-gray-900" : "bg-[#f4f5fb]"
      } items-center justify-between rounded-3xl p-4 h-28 flex`}
    >
      <div className="flex flex-col gap-1">
        <h2 className="opacity-60">{title}</h2>
        <h2 className="md:text-2xl text-gray-600">{value}</h2>
      </div>
    </div>
  );
}
