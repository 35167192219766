import { useQuery } from "@tanstack/react-query";
import axiosF, { AxiosError } from "axios";
import { SiweMessage } from "siwe";
import {
  WalletClient,
  useAccount,
  useMutation,
  useQueryClient,
  useWalletClient,
} from "wagmi";
import { peaqAgung } from "@/lib/peaqAgung";
import { z } from "zod";
import { ProfileSchema } from "./utils";
import { config } from "./config";

const axios = axiosF.create({
  withCredentials: true,
  baseURL: config.VITE_BACKEND_ADDR,
});

async function createSiweMessage({
  domain,
  address,
  statement,
}: {
  domain: string;
  address: string;
  statement: string;
}) {
  const result = await axios.get("/auth/nonce");
  const nonce = result.data.nonce as string;
  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: "1",
    chainId: peaqAgung.id,
    nonce,
  });
  return { message: message.prepareMessage(), nonce };
}

const loginPostResult = z.object({
  access_token: z.string(),
});

async function signIn(signFunc: WalletClient["signMessage"], address: string) {
  const { message, nonce } = await createSiweMessage({
    address,
    domain: window.location.hostname,
    statement: "Sign in with Ethereum to the app.",
  });
  const signature = await signFunc({ message });
  const result = await axios.post("/auth/verify", {
    message,
    signature,
    nonce,
  });

  return loginPostResult.parse(result.data);
}

export async function getSdkToken(accessToken: string) {
  const result = await axios.post(
    "/profile/kyc/token",
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return result.data.token as string;
}

async function getProfile(accessToken: string) {
  const me = await axios.get("/profile", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return ProfileSchema.parse(me.data.profile);
}

export const createProfileBodySchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
});

type CreateProfileBody = z.infer<typeof createProfileBodySchema>;

export async function createProfile(
  accessToken: string,
  body: CreateProfileBody
) {
  const profile = await axios.post("/profile", body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return profile.data;
}

export async function resendVerificationEmail(accessToken: string) {
  const result = await axios.post(
    "/profile/resend-verification-email",
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return result.data as { success: boolean };
}

export const useAccessToken = () => {
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient();

  return useQuery({
    queryKey: ["accessToken"],
    queryFn: async () => {
      if (!walletClient || !address) {
        throw new Error("No wallet client or address");
      }
      const { access_token: backendAccessToken } = await signIn(
        walletClient.signMessage,
        address
      );
      localStorage.setItem("accessToken", backendAccessToken);
      return backendAccessToken;
    },
    initialData: () => localStorage.getItem("accessToken"),
    enabled:
      !!walletClient && !!address && !localStorage.getItem("accessToken"),
    refetchOnWindowFocus: false,
    refetchInterval: Infinity,
  });
};

const useProfile = () => {
  const accessState = useAccessToken();
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["profile"],
    retry: false,
    queryFn: async () => {
      if (!accessState.isSuccess || !accessState.data) {
        throw new Error("No access token");
      }
      try {
        const result = await getProfile(accessState.data);
        return result;
      } catch (error: unknown) {
        if ((error as AxiosError).response?.status === 403) {
          localStorage.removeItem("accessToken");
          queryClient.invalidateQueries({ queryKey: ["accessToken"] });
        } else {
          throw error;
        }
      }
    },
    enabled: accessState.isSuccess,
  });
};

export const useSdkToken = () => {
  const accessState = useAccessToken();
  return useMutation({
    mutationKey: ["sdkToken"],
    mutationFn: async () => {
      if (!accessState.isSuccess || !accessState.data) {
        throw new Error("No access token");
      }
      const result = await getSdkToken(accessState.data);
      return result;
    },
  });
};

export const useBackend = () => {
  const accessState = useAccessToken();
  const profile = useProfile();

  return {
    accessState,
    profile,
  };
};
