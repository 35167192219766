import close from "@/assets/close.png";
import { useApproveSecToken } from "@/contracts/eloopNetwork/secToken";
import {
  ADDR as stakingAddr,
  useRemoveStake,
  useStakedToken,
} from "@/contracts/eloopNetwork/stakingContract";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Button } from "@/components/ui/button";
import { waitForTransaction } from "@wagmi/core";
import { peaqAgung } from "@/lib/peaqAgung";
import { toast } from "sonner";
import { formatErrorMessage } from "@/lib/utils";
import { useQueryClient } from "wagmi";

const UnStakeSecurityTokenModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [stakeAmount, setStakeAmount] = useState<number>(1);
  const wagmiClient = useQueryClient();
  const { remove: removeStake, prepared: removeStakePrepared } = useRemoveStake(
    BigInt(`${stakeAmount}`),
    {
      writeConfig: {
        async onSuccess(data) {
          toast("Unstaking RD2049");
          const tx = await waitForTransaction({
            hash: data.hash,
            chainId: peaqAgung.id,
          });
          toast(`Successfully unstaked RD2049 (${tx.transactionHash})`);
          setIsOpen(false);
          wagmiClient.invalidateQueries();
        },
      },
    }
  );
  const [isApprovalLoading, setIsApprovalLoading] = useState(false);
  const { approve: approveSecTokenStake, prepared: approvePrepared } =
    useApproveSecToken(stakingAddr, BigInt(`${stakeAmount}`), {
      preparedConfig: {
        enabled: removeStakePrepared.status !== "success",
      },
      writeConfig: {
        async onSuccess(data) {
          toast("Approving RD2049");
          setIsApprovalLoading(true);
          const tx = await waitForTransaction({
            hash: data.hash,
            chainId: peaqAgung.id,
          });
          setIsApprovalLoading(false);
          toast(`Successfully approved RD2049 (${tx.transactionHash})`);
        },
      },
    });
  const { balance: stakedTokenBalance } = useStakedToken();
  return (
    <>
      {stakedTokenBalance.data?.formatted != null &&
        Number(stakedTokenBalance.data?.formatted) > 0 && (
          <button
            onClick={() => {
              setIsOpen(true);
            }}
            className="text-sm z-50 font-light cursor-pointer focus:outline-none bg-transparent text-black rounded-full py-3 grid place-content-center "
          >
            Exit Reward Pool
          </button>
        )}
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="h-screen transition-all duration-500  z-50 w-screen fixed top-0 left-0 overflow-hidden bg-opacity-70 bg-gray-900 grid place-content-center">
          <Dialog.Panel className="p-10 w-[30rem] bg-white flex flex-col  relative rounded-lg border shadow gap-2">
            <div
              onClick={() => setIsOpen(false)}
              className="absolute cursor-pointer top-5 right-5"
            >
              <img src={close} />
            </div>
            <div className="flex items-start gap-3 flex-col justify-start">
              <h2 className="text-2xl text-start font-bold">Unstake RD2049</h2>
            </div>
            <div className="flex mt-10 flex-col gap-8 items-start">
              <div className="border items-end justify-between flex bg-gray-100 w-full p-4 rounded-lg border-blue-600">
                <div className="flex gap-4 flex-col">
                  <h2 className="text-sm ">You unstake</h2>
                  <input
                    className="rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900  focus:border-none focus:ring-0 focus:outline-none text-2xl  focus:border-transparent  bg-gray-100 border-none placeholder:text-gray-400 "
                    value={stakeAmount}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setStakeAmount(0);
                      } else {
                        setStakeAmount(parseInt(e.target.value));
                      }
                    }}
                  />
                </div>
                <p className="border-2  rounded-full text-sm px-4 py-2">
                  RD2049
                </p>
              </div>
            </div>
            {removeStakePrepared.status !== "success" ? (
              <Button
                onClick={() => approveSecTokenStake.write?.()}
                loading={approveSecTokenStake.isLoading || isApprovalLoading}
                disabled={approvePrepared.status !== "success"}
                className="bg-black text-white  w-full rounded-full py-3"
              >
                Approve
              </Button>
            ) : null}
            {approveSecTokenStake.error ? (
              <p className="text-red-500">
                {approveSecTokenStake.error.message}
              </p>
            ) : null}
            <Button
              loading={removeStakePrepared.isLoading || removeStake.isLoading}
              onClick={() => removeStake.write?.()}
              disabled={removeStakePrepared.status !== "success"}
              className="bg-black mt-5 text-white  w-full rounded-full py-3"
            >
              Unstake
            </Button>
            {removeStakePrepared.error ? (
              <ErrorMessage
                message={formatErrorMessage(removeStakePrepared.error)}
              />
            ) : null}
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

function ErrorMessage({ message }: { message: string }) {
  if (message.includes("Please approve a higher amount"))
    return <p>{message}</p>;

  <p className="text-red-500">Cannot unstake RD2049: {message}</p>;
}

export default UnStakeSecurityTokenModal;
