// import { useEOTToken } from "@/contracts/eloopNetwork/EOTToken";
import { Card } from "./components/card";
// import CashbackTiers from "./components/cashbackTiers";
// import bgCashout from "./assets/bg-cashout.svg";
import { useEffect, useState } from "react";
import bgBalance from "../ecosystem/assets/bg-balance.svg";

import { getDashboardData } from "../api/api";
import LoadingOverlay from "./components/loadingOverlay";
import { ArrowRight, InfoIcon } from "lucide-react";
import { useSecPoolData } from "@/contracts/eloopNetwork/secPool";
import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";
import { useBackend } from "@/lib/useBackend";

function WalletPage() {
  const [dashboardData, setDashboardData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { profile, accessState } = useBackend();
  async function getData() {
    setLoading(true);
    const response = await getDashboardData();
    if (response !== 500) {
      setDashboardData(response);
      setLoading(false);
    }
  }

  const navigate = useNavigate();

  const { address } = useAccount();

  const data = useSecPoolData(address || "0x0");

  useEffect(() => {
    getData();
  }, []);

  console.log({ profile, accessState });

  const isLoggedIn = accessState.data;

  const hasProfile = profile.data;

  const shouldShowProfileMessage = isLoggedIn && !hasProfile;

  return (
    <div className="no-scrollbar flex-1 gap-7  bg-white rounded-3xl md:rounded-none md:rounded-b-3xl  transition-all pt-2 p-3 md:p-10 pb-0  flex flex-col overflow-scroll">
      {loading ? (
        <div className="grid h-full place-content-center ">
          <LoadingOverlay />
        </div>
      ) : (
        <div className="no-scrollbar">
          <h2 className="md:hidden text-3xl my-4 font-semibold">Wallet</h2>
          <div
            style={{
              backgroundImage: `url(${bgBalance})`,
              backgroundSize: "cover",
            }}
            className={`w-full bg-[#F8F9FF] flex-col  gap-2  justify-between rounded-3xl  px-4 py-8 mb-4 flex`}
          >
            <h2 className="text-md text-gray-600">Available RD2049 Tokens</h2>
            <div className="flex flex-row  items-center justify-between">
              <h2 className="text-xl">{`${
                data.formatted.secTokenBalance !== null
                  ? data.formatted.secTokenBalance
                  : "?"
              } RD2049`}</h2>

              {/* buy mUSDC button */}
              <div
                className="flex text-white cursor-pointer font-normal items-center justify-center gap-4 w-48 py-3 rounded-3xl bg-black"
                onClick={() => navigate("/ecosystem")}
              >
                <h2>Get Tokens</h2>
                <ArrowRight width={20} />
              </div>
            </div>
          </div>
          {shouldShowProfileMessage && (
            <div
              style={{
                backgroundImage: `url(${bgBalance})`,
                backgroundSize: "cover",
              }}
              className={`w-full bg-[#F8F9FF]  gap-2  justify-between rounded-3xl  px-4 py-8 mb-4 flex flex-col`}
            >
              <h2 className="text-md text-gray-600 flex items-center gap-1">
                <InfoIcon className="h-4 w-4" />
                Missing profile
              </h2>
              <div className="flex flex-col md:flex-row gap-2 items-center justify-between">
                <h2 className="text-xl max-w-2xl">
                  Complete your profile setup and verify your email to be
                  whitelisted. Once verified, you will gain the permissions
                  needed to purchase RD2049 tokens.
                </h2>

                {/* buy mUSDC button */}
                <div
                  className="flex text-white cursor-pointer font-normal items-center justify-center gap-4 w-48 py-3 rounded-3xl bg-black"
                  onClick={() => navigate("/profile")}
                >
                  <h2>Click here</h2>
                  <ArrowRight width={20} />
                </div>
              </div>
            </div>
          )}
          <div className="flex  gap-2 items-center flex-col bg-background p-2 rounded-3xl">
            <div className="flex flex-col md:flex-row gap-4 md:gap-7 w-full">
              <Card
                value={dashboardData.total_items.toFixed(2).toString() + " AED"}
              />
              <Card
                bg="bg-[#1e1e1e]"
                title="Orders"
                value={dashboardData.total_orders}
                growth="17"
              />
              <Card
                bg="bg-[#1e1e1e]"
                title="Profit"
                value={
                  dashboardData.total_revenue.toFixed(2).toString() + " AED"
                }
                growth="24"
              />
            </div>
            {/*chart. operating profit  */}
            {/* <div className="w-full relative  z-0 pr-10 items-center  flex bg-white rounded-xl"> */}
            {/* <div className="w-full h-auto  flex gap-3 flex-col px-5 py-3 "> */}
            {/* <div className="flex flex-col md:flex-row items-start md:items-center  justify-between">
                  <h2 className="text-lg md:ml-10  font-light py-2">
                    Operating Profit
                  </h2>
                  <div className="flex gap-4 items-center">
                    <div
                      onClick={() => {
                        setChartInterval("month");
                        getData("month");
                      }}
                      className={`py-2.5 px-6 cursor-pointer  text-gray-600  rounded-full text-sm ${
                        chartInterval === "month"
                          ? "bg-black text-white"
                          : "bg-[#F8F9FF]"
                      }`}
                    >
                      Month
                    </div>
                    <div
                      onClick={() => {
                        setChartInterval("week");
                        getData("week");
                      }}
                      className={`py-2.5 px-6 cursor-pointer  text-gray-600  rounded-full text-sm ${
                        chartInterval === "week"
                          ? "bg-black text-white"
                          : "bg-[#F8F9FF]"
                      }`}
                    >
                      Week
                    </div>
                  </div>
                </div> */}
            {/* <div className="flex flex-col md:flex-row gap-20 pt-4 justify-start ml-10 border-t  ">
                  <Legend
                    value={dashboardData.totalsInSelectedTimeframe.revenue
                      .toFixed(2)
                      .toString()}
                  />
                  <Legend
                    value={dashboardData.totalsInSelectedTimeframe.cost
                      .toFixed(2)
                      .toString()}
                    title="Cost"
                    color="bg-red-400"
                  />
                  <Legend
                    value={dashboardData.totalsInSelectedTimeframe.profit
                      .toFixed(2)
                      .toString()}
                    title="Profit"
                    color="bg-blue-400"
                  />
                </div>
                <OperatingProfitChart chartData={dashboardData.myChartData} /> */}
            {/* </div> */}
            {/* </div> */}
          </div>
          {/* 4 col, 2 row grid */}

          <h2 className="text-3xl mt-10 ml-4 mb-2">XMAQUINA pool</h2>
          <div className="flex flex-col   p-2 gap-2 rounded-3xl bg-background">
            <div className="flex flex-col md:flex-row gap-2 md:gap-10">
              <TokenStats active title="Robots in pool" value={"1"} />
              <TokenStats
                active
                title="Average items per order"
                value={dashboardData.average_items_per_order.toFixed(2)}
              />
              <TokenStats
                active
                title="Average revenue per order"
                value={
                  dashboardData.average_revenue_per_order.toFixed(2) + " AED"
                }
              />
              <TokenStats
                active
                title="Average topping per order"
                value={dashboardData.average_toppings_per_order.toFixed(2)}
              />
            </div>
          </div>
          {/* <h2 className="text-3xl mt-10 ml-4 mb-2">Cashback Tiers</h2>
          <CashbackTiers /> */}
          <h2 className="mt-5 text-sm mb-5 md:-mb-5 self-center w-full text-center  text-gray-500">
            Powered by{" "}
            <span
              onClick={() => window.open("https://teneo.pro", "_blank")}
              className="text-blue-500 cursor-pointer"
            >
              Teneo Protocol
            </span>
          </h2>
        </div>
      )}
    </div>
  );
}

export { WalletPage };

function TokenStats({
  active = false,
  title = "Cashout balance",
  value = "0.00 AED",
}) {
  return (
    <div
      className={`w-full md:w-1/4 ${
        active ? "bg-white text-gray-900" : "bg-[#F8F9FF]"
      } items-center justify-between rounded-3xl p-4 h-[125px] flex`}
    >
      <div className="flex flex-col gap-1">
        <h2 className="opacity-60">{title}</h2>
        <h2 className="text-2xl font-semibold">{value}</h2>
      </div>
    </div>
  );
}
