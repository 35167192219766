import { Fragment, useEffect, useState } from "react";
import close from "@/assets/close.png";
import { getDashboardData } from "../api/api";
import bgBalance from "./assets/bg-balance.svg";

import { Card } from "./components/card";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ChevronDown, Info } from "lucide-react";

import LoadingOverlay from "./components/loadingOverlay";

import { useMUSDCToken } from "@/contracts/eloopNetwork/mUSDCToken";
import { useSecPoolData } from "@/contracts/eloopNetwork/secPool";
import { useSecToken } from "@/contracts/eloopNetwork/secToken";
import { useStakedToken } from "@/contracts/eloopNetwork/stakingContract";
import { Dialog, Transition } from "@headlessui/react";

import { formatUnits } from "viem";
import { useAccount } from "wagmi";
import BuySecurityTokenModal from "../buyCoin/components/BuySecurityTokenModal";
import ClaimRewardsModal from "../buyCoin/components/ClaimRewardsModal";
import StakeSecurityTokenModal from "../buyCoin/components/StakeSecurityTokenModal";
import BuyAndStake from "./components/buyAndStake";
import DiscordPopup from "./components/discordPopup";
// import UnStakeSecurityTokenModal from "../buyCoin/components/UnStakeSecurityTokenModal";
import { useWhitelistedState } from "@/contracts/eloopNetwork/utilsContract";
import CarsTable from "./components/carsTable";
import UnStakeSecurityTokenModal from "../buyCoin/components/UnStakeSecurityTokenModal";
import Collapsible from "react-collapsible";
import { roundNumber } from "@/lib/utils";

function EcosystemPage() {
  const [dashboardData, setDashboardData] = useState<any>();

  const [isBuyPopupOpen, setIsBuyPopupOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  async function getData() {
    setLoading(true);
    const response = await getDashboardData();
    if (response !== 500) {
      setDashboardData(response);
      setLoading(false);
    }
  }

  const [discordPopup, setDiscordPopup] = useState(false);

  const { address } = useAccount();

  const data = useSecPoolData(address ?? "0x0");

  const { balance: secTokenBalance } = useSecToken();

  const whitelisted = useWhitelistedState(address ?? "0x0");

  useEffect(() => {
    getData();
  }, []);
  const { balance: mUSDCBalance, token: mUSDCToken } = useMUSDCToken();
  const { balance: stakedTokenBalance } = useStakedToken();
  return (
    <div className="no-scrollbar flex-1 gap-7  bg-white rounded-3xl md:rounded-none md:rounded-b-3xl  transition-all pt-2 p-3 md:p-10 pb-0  flex flex-col overflow-scroll">
      {loading ? (
        <div className="grid h-full place-content-center ">
          <LoadingOverlay />
        </div>
      ) : (
        <div className="no-scrollbar">
          <Transition appear show={discordPopup} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50"
              onClose={() => setDiscordPopup(false)}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/60" />
              </Transition.Child>
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-100"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full  max-w-md transform overflow-hidden rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all">
                      <DiscordPopup closePopup={() => setDiscordPopup(false)} />
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          <div className="flex  gap-2 items-center flex-col bg-background p-2 rounded-3xl">
            <div className="flex md:flex-row flex-col gap-2 md:gap-7 w-full">
              <Card title="Robots in pool" value={"1"} />
              <Card
                title="Total orders"
                value={dashboardData.total_orders.toString()}
              />
              <Card
                title="Total revenue"
                value={
                  dashboardData.total_revenue.toFixed(2).toString() + " AED"
                }
              />
              <Card
                title="Total products"
                value={dashboardData.total_items.toString()}
              />
            </div>
          </div>

          <div className="bg-white border-8 gap-2 flex flex-col rounded-3xl mt-5 p-5 border-background">
            <Collapsible
              transitionTime={200}
              trigger={
                <div className="flex w-full justify-between items-center">
                  <h2 className="text-xl">How it works</h2>
                  <ChevronDown />
                </div>
              }
            >
              <ol className="list-decimal font-sans list-inside">
                {/* <li>
                  Get the KREST tokens from the{" "}
                  <span
                    onClick={() => setDiscordPopup(true)}
                    className="underline text-blue-700 cursor-pointer"
                  >
                    Discord faucet.
                  </span>{" "}
                </li> */}
                <li>
                  Fill out your the profile to get whitelisted. The bot will
                  give you the permissions to buy the RD2049 tokens.
                </li>
                <li>
                  Complete tasks on{" "}
                  <a
                    href="https://guild.xyz/eloop"
                    className="text-blue-500 underline"
                    target="_blank"
                  >
                    Guild
                  </a>{" "}
                  to earn USDM.
                </li>
                <li>
                  With USDM, you can buy RD2049 and stake them to get rewards.
                  Rewards are being distributed once per hour.
                </li>
              </ol>
            </Collapsible>

            <p className="font-sans">
              Permission status:{" "}
              <span
                className={
                  whitelisted.data !== 3 ? "text-red-500" : "text-green-500"
                }
              >
                {whitelisted.data === 3 ? "Whitelisted" : "Not whitelisted"}
              </span>
            </p>
            <h2 className="text-sm text-red-700">
              Be aware that this is a test dashboard and might contain bugs.
              Please let us know if you find any.
            </h2>
          </div>
          {/* your balance */}
          <div
            style={{
              backgroundImage: `url(${bgBalance})`,
              backgroundSize: "cover",
            }}
            className={`w-full  h-32  
              bg-[#F8F9FF] flex-row items-center   justify-between rounded-3xl mt-10 p-4  flex`}
          >
            <div className="flex flex-col px-10 items-start">
              <h2 className="text-md text-gray-600">Your balance</h2>
              <h2 className="text-xl">
                {" "}
                {mUSDCBalance.data?.formatted
                  ? parseFloat(mUSDCBalance.data?.formatted.toString()).toFixed(
                      2
                    )
                  : 0}{" "}
                {mUSDCToken.data?.symbol}
              </h2>
            </div>
            {/* buy mUSDC button */}
            {/* <div
              onClick={() => setDiscordPopup(true)}
              className="flex text-white cursor-pointer font-normal items-center justify-center gap-4 w-48 py-3 rounded-3xl bg-black"
            >
              <h2>Get USDM</h2>
              <ArrowRight width={20} />
            </div> */}
          </div>
          <h2 className="text-2xl mt-10 ml-4">XMAQUINA Pools</h2>
          <div className="bg-white border-8 rounded-3xl mt-5 md:p-5 p-4 border-background">
            <div className="flex md:flex-row flex-col">
              <div className=" w-full  gap-5 text-sm text-gray-800 flex flex-col">
                <div className="flex md:flex-row md:items-center flex-col w-full gap-10">
                  <h2 className="text-2xl ">XMAQUINA </h2>
                  <h2>
                    Unclaimed rewards:{" "}
                    {data.formatted.stakingReward !== null
                      ? parseFloat(
                          formatUnits(
                            data.formatted.stakingReward,
                            18
                          ).toString()
                        ).toFixed(2)
                      : "?"}{" "}
                    KREST
                  </h2>
                  <Popover>
                    <PopoverTrigger>
                      <h2 className="flex gap-1">
                        Price per token:{" "}
                        {data.formatted.tokenPrice
                          ? roundNumber(data.formatted.tokenPrice, 2)
                          : "?"}{" "}
                        USDM <Info className="w-4 h-4" />
                      </h2>
                    </PopoverTrigger>
                    <PopoverContent>
                      <p className="w-80">Teneo Protocol Fee included.</p>
                    </PopoverContent>
                  </Popover>
                  <h2>Robots in pool: {"1"}</h2>
                </div>
                <h2 className="text-sm font-sans text-gray-500">
                  XMAQUINA allows you to participate in the revenues of a
                  robotic coffee shop.
                  <br /> Buying tokens and joining the reward pool enables you
                  to earn a portion of the earnings from the machine.
                  <br /> Participating in the XMAQUINA pool is therefore a great
                  way to earn passive income while supporting the business to
                  finance their assets.
                </h2>
                <div className="flex md:flex-row flex-col gap-2">
                  <Card
                    title="Available to buy"
                    value={`${
                      data.formatted.secTokenBalance !== null
                        ? data.formatted.secTokenBalance
                        : "?"
                    } RD2049`}
                    border="border-[#f4f5fb]"
                    actions={
                      mUSDCBalance.data?.value && mUSDCBalance.data?.value > 0
                        ? [<BuySecurityTokenModal />]
                        : [<HowItWorksModal />]
                    }
                  />

                  <Card
                    title="Your tokens"
                    value={`${secTokenBalance.data?.formatted} ${secTokenBalance.data?.symbol}`}
                    border="border-[#f4f5fb]"
                    actions={[<StakeSecurityTokenModal />]}
                  />

                  <Card
                    title="In Reward Pool"
                    value={
                      stakedTokenBalance.data?.formatted +
                      " " +
                      stakedTokenBalance.data?.symbol
                    }
                    border="border-[#f4f5fb]"
                    actions={[
                      <ClaimRewardsModal />,
                      <UnStakeSecurityTokenModal />,
                    ]}
                  />
                </div>
                {/* buy and unstake buttons. but unstake is disabled */}
                {/* <div className="flex md:flex-row flex-col gap-5">
                  <BuySecurityTokenModal />
                  <StakeSecurityTokenModal />
                  <ClaimRewardsModal />
                </div> */}
              </div>
            </div>
            <CarsTable machineData={dashboardData} />
          </div>
          <h2 className="mt-5 text-sm mb-5 md:-mb-5 self-center w-full text-center  text-gray-500">
            Powered by{" "}
            <span
              onClick={() => window.open("https://teneo.pro", "_blank")}
              className="text-blue-500 cursor-pointer"
            >
              Teneo Protocol
            </span>
          </h2>
        </div>
      )}
      {/* buy and stake popup */}
      <BuyAndStake
        setIsBuyPopupOpen={setIsBuyPopupOpen}
        isBuyPopupOpen={isBuyPopupOpen}
      />
    </div>
  );
}

const HowItWorksModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        className="text-sm bg-black focus:outline-none z-50 font-light cursor-pointer  text-white px-10  rounded-full py-3 flex justify-center gap-1"
      >
        Get USDM Tokens
      </button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="h-screen transition-all duration-500 z-50 w-screen fixed top-0 left-0 overflow-hidden bg-opacity-70 bg-gray-900 grid place-content-center">
          <Dialog.Panel className="md:p-10 p-5 mx-5 md:mx-0 md:w-[30rem] bg-white flex flex-col  relative rounded-3xl border shadow gap-2">
            <div
              onClick={() => setIsOpen(false)}
              className="absolute cursor-pointer top-9 right-5"
            >
              <img src={close} />
            </div>
            <div className="flex items-start gap-3 flex-col justify-start">
              <h2 className="text-2xl text-start font-bold">
                How to get more USDM and Rewards
              </h2>
            </div>
            <div className="flex mt-10 flex-col gap-8 items-start">
              <ol className="list-decimal font-sans list-inside gap-2 flex flex-col">
                {/* <li>
                  Get the KREST tokens from the{" "}
                  <span
                    onClick={() => setDiscordPopup(true)}
                    className="underline text-blue-700 cursor-pointer"
                  >
                    Discord faucet.
                  </span>{" "}
                </li> */}
                <li>
                  Fill out your the profile to get whitelisted. The bot will
                  give you the permissions to buy the RD2049 tokens.
                </li>
                <li>
                  Complete tasks on{" "}
                  <a
                    href="https://guild.xyz/eloop"
                    className="text-blue-500 underline"
                    target="_blank"
                  >
                    Guild
                  </a>{" "}
                  to earn USDM.
                </li>
                <li>
                  With USDM, you can buy RD2049 and stake them to get rewards.
                  Rewards are being distributed once per hour.
                </li>
              </ol>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default BuySecurityTokenModal;

export { EcosystemPage };
