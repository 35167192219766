import close from "@/assets/close.png";
import { Button } from "@/components/ui/button";
import { useSecPoolData } from "@/contracts/eloopNetwork/secPool";
import { useClaimReward } from "@/contracts/eloopNetwork/stakingContract";
import { peaqAgung } from "@/lib/peaqAgung";
import { formatErrorMessage } from "@/lib/utils";
import { Dialog } from "@headlessui/react";
import { waitForTransaction } from "@wagmi/core";
import { useState } from "react";
import { toast } from "sonner";
import { useAccount, useQueryClient } from "wagmi";
import { formatUnits } from "viem";

const ClaimRewardsModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const wagmiClient = useQueryClient();
  const { claim, prepared } = useClaimReward({
    writeConfig: {
      async onSuccess(data) {
        toast("Claiming rewards...");
        const tx = await waitForTransaction({
          hash: data.hash,
          chainId: peaqAgung.id,
        });
        toast(`Successfully claimed the rewards (${tx.transactionHash})`);
        setIsOpen(false);
        wagmiClient.invalidateQueries();
      },
    },
  });
  const { address } = useAccount();
  const data = useSecPoolData(address ?? "0x0");
  const total =
    data.formatted.stakingReward !== null
      ? parseFloat(
          formatUnits(data.formatted.stakingReward, 18).toString()
        ).toFixed(2)
      : "?";
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        className="text-sm  font-light grid place-content-center h-10 border px-10 border-black cursor-pointer bg-white text-black  rounded-full py-3   gap-1"
      >
        Claim Rewards
      </button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="h-screen transition-all duration-500 z-50 w-screen fixed top-0 left-0 overflow-hidden bg-opacity-70 bg-gray-900 grid place-content-center">
          <Dialog.Panel className="md:p-10 p-5 mx-5  md:mx-0 w-[20rem] md:w-[30rem] bg-white flex flex-col  relative rounded-3xl border shadow gap-2">
            <div
              onClick={() => setIsOpen(false)}
              className="absolute cursor-pointer top-5 right-5"
            >
              <img src={close} />
            </div>
            <div className="flex items-start md:gap-3 gap-5 flex-col justify-start">
              <h2 className="md:text-2xl  text-xl text-start font-bold">
                Claim Rewards
              </h2>
              <p>You'll get {total} KREST</p>
            </div>
            <div className="flex gap-2 flex-col">
              <Button
                disabled={prepared.error !== null}
                loading={prepared.isLoading || claim.isLoading}
                onClick={() => claim.write?.()}
                className="bg-black mt-4 text-white rounded-full py-2 w-full "
              >
                Claim
              </Button>
              {prepared.error ? (
                <ErrorMessage message={formatErrorMessage(prepared.error)} />
              ) : null}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

function ErrorMessage({ message }: { message: string }) {
  if (message.includes("Please approve a higher amount"))
    return <p>{message}</p>;

  <p className="text-red-500">Cannot claim rewards: {message}</p>;
}

export default ClaimRewardsModal;
